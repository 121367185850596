import React, { Fragment, useEffect, useState } from 'react';
import './style.css';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { TextField, TextareaAutosize } from '@mui/material';
import getImageURL from '../../_helpers/imageHelper';
import ImageDragDrop from '../../components/ImageDragDrop';
import PlusIcon from '../../components/PlusIcon';
import { toast } from 'react-toastify';
import fetchImageSize from '../../_helpers/fetchImageSize';
import { uploaderService } from '../../_services/uploader.service';
import { APILoader } from '../../components/APILoader';
import axios from 'axios';
import { apiService } from '../../_services/api.service';
import ShareModal from '../../components/ShareModal';
import config from '../../_config';
import QuickToolsModal from '../../components/QuickToolsModal';

const Art = ({ company }) => {
    const [images, setImages] = useState([]);
    const [imageInit, setImageInit] = useState(false);

    const [wizards, setWizards] = useState([
        { name: "Select Art", icon: 'assets/wizard-icons/image.png', active: true },
        { name: "Create Gang", icon: 'assets/wizard-icons/print.png', active: true },
        { name: "Download", icon: 'assets/wizard-icons/cloud.png', active: false }
    ]);
    const [activeWizard, setActiveWizard] = useState(1);

    const [sheetWidth, setSheetWidth] = useState(22.5);
    const [sheetLength, setsheetLength] = useState(120);
    const [imageMargin, setimageMargin] = useState(0.25);
    const [rotateGang, setRotateGang] = useState(false);
    const sizeUp = [
        1, 2, 3, 4, 5
    ]
    const [uploaderBusy, setUploaderBusy] = useState(false);
    const [wizardBusy, setWizardBusy] = useState(false);
    // const [downloadPaths, setDownloadPaths] = useState([
    //     "https://jdcstorage.blob.core.windows.net/0000jdcllc/ef652cb3-a71e-44e3-aa36-2fdfd7bb7d1c.png",
    //     "https://jdcstorage.blob.core.windows.net/0000jdcllc/be7ac32f-b22b-42ab-a06a-19461a2691f9.png",
    //     "https://jdcstorage.blob.core.windows.net/0000jdcllc/03d50ab4-af30-4930-ad53-0050af978f22.png",
    // ]);
    const [downloadPaths, setDownloadPaths] = useState([]);
    const [downloadPathsPreview, setDownloadPathsPreview] = useState([]);
    const [artLoaded, setArtLoaded] = useState(null);

    const { artId, type } = useParams();
    const artId_Local = localStorage.getItem("art");
    const [shareModal, setShareModal] = useState(false);
    const [artLocalId, setArtLocalId] = useState(null);

    const sharingDataDefault = {
        from: "",
        to: "",
        subject: "Check out these shared Gang Sheets!",
        text: "Download my gang sheets from Gang-Sheeter.com by clicking the button below"
    };
    const [sharingData, setSharingData] = useState(sharingDataDefault);
    const [attachmentFile, setattachmentFile] = useState(null);
    const [editable, seteditable] = useState(false);
    const [quickToolsModal, setQuickToolsModal] = useState(false);
    const [quickToolsIndex, setQuickToolsIndex] = useState(-1);
    const quickToolImage = quickToolsIndex != -1 && images.length > 0 ? images[quickToolsIndex] : null;
    const quickSizeData = [{
        category: "Youth",
        data: [
            { name: "Full Size", value: 8 },
            { name: "Chest/Sleeve", value: 3 },
        ]
    },
    {
        category: "Adult",
        data: [
            { name: "Full Size", value: 11 },
            { name: "Chest/Sleeve", value: 3.5 },
        ]
    },
    {
        category: "Plus",
        data: [
            { name: "Full Size", value: 13 },
            { name: "Chest/Sleeve", value: 4.5 },
        ]
    }];
    const isMobile = window.matchMedia('screen and (max-width: 768px)').matches ? true : false;

    const [sidebarVisible, setSidebarVisible] = useState(false);

    const quickToolsSideMenu = [
        { name: "Home", icon: "fa-solid fa-home" },
        { name: "Crop", icon: "fa-solid fa-crop", development: true },
        { name: "Remove Background", icon: "fa-solid fa-scissors", development: true },
        { name: "Rotate", icon: "fa-solid fa-rotate", development: true },
        { name: "Add Label", icon: "fa-solid fa-tag" },
        { name: "Quick Size", icon: "fa-solid fa-caret-down" },
    ];

    const [quickToolsActive, setQuickToolsActive] = useState(0);
    const [quickToolsLabel, setQuickToolsLabel] = useState("");
    const [labeledImage, setLabeledImage] = useState(null);

    const navigate = useNavigate();


    useEffect(() => {
        if (company) {
            setSheetWidth(company.gangSheetWidth);
            setsheetLength(company.gangSheetLength);
            setimageMargin(company.gangSheetImageMargin);
        }
    }, [company]);
    useEffect(() => {
        const tArtId = artId || artId_Local;
        const navigateBackTo = company ? `/c/${company._id}` : '/';
        if (tArtId) {
            setArtLocalId(null);
            setWizardBusy(true);
            apiService.getSingle("arts", tArtId).then(g => {
                if (g.data.length == 0) {
                    return navigate(navigateBackTo);
                }

                setArtLocalId(localStorage.getItem(`a-${tArtId}`));

                localStorage.setItem("images", JSON.stringify(g.data));
                setArtLoaded(g);
                setImages(g.data);
                setImageInit(true);
                setWizardBusy(false);

                if (g.generatedURLs && type === "download") {
                    setActiveWizard(2);
                    setDownloadPaths(g.generatedURLs);
                    // setDownloadPathsPreview(g.generatedURLsPreview);
                    loadPreviewImages(g.generatedURLsPreview)
                } else {
                    setActiveWizard(1);
                }
            }).catch(e => {
                return navigate(navigateBackTo);
            })
        }
        // else {
        //     console.log("Loaded from local");
        //     let images = localStorage.getItem("images");
        //     if (!images) {
        //         return navigate("/");
        //     }

        //     try {
        //         images = JSON.parse(images);
        //         if (images.length == 0) {
        //             return navigate("/");
        //         }
        //         setImages(images);
        //         setImageInit(true);

        //     } catch (error) {
        //         return navigate("/");
        //     }
        // }

    }, [artId]);

    useEffect(() => {
        if (images) {
            let interval = setInterval(() => {
                localStorage.setItem("images", JSON.stringify(images));
            }, 3000);
            return () => {
                if (interval) {
                    clearInterval(interval);
                }
            }
        }
    }, [images]);

    useEffect(() => {
        if (images && images.length > 0 && imageMargin && !isNaN(parseFloat(imageMargin))) {
            evaluateArtGangWidth();
        }
    }, [imageMargin, imageInit]);

    useEffect(() => {
        if (!quickToolsModal) {
            setQuickToolsIndex(-1);
            setSidebarVisible(false);
            setLabeledImage(null);
            setQuickToolsLabel("");
            setQuickToolsActive(0);
        }
    }, [quickToolsModal]);

    useEffect(() => {
        if (shareModal) {
            seteditable(false);
            setattachmentFile(null);
            let emailLocal = localStorage.getItem("email");
            emailLocal = emailLocal ? emailLocal : '';
            setSharingData({ ...sharingDataDefault, from: emailLocal });
        }
    }, [shareModal]);

    const evaluateArtGangWidth = () => {
        let cImages = JSON.parse(JSON.stringify(images));
        for (let i = 0; i < cImages.length; i++) {
            let cImage = cImages[i];
            let width_inch = parseFloat(cImage.sizeObj.width_inch);
            if (!isNaN(width_inch)) {
                cImage.sizeObj.gang_height = getArtGangWidth(width_inch);
            }
            cImages[i] = cImage;
        }
        setImages(cImages);
    }

    const getArtGangWidth = (width_inch) => {
        let res = Math.round(parseFloat(width_inch) + parseFloat(imageMargin))
        return isNaN(res) ? 1 : res;
    }

    const getDPI = (pix, inch) => {
        let dpi = Math.round(pix / inch);
        let dpiObj = { dpi, status: "Excellent", color: "#10AACC", textColor: "#FFF" };
        if (dpi >= 300) {
            dpiObj = { dpi, status: "Excellent", color: "#10AACC", textColor: "#FFF" }
        } else if (dpi >= 200 && dpi < 300) {
            dpiObj = { dpi, status: "Ok", color: "#009467", textColor: "#FFF" }
        } else if (dpi >= 100 && dpi < 200) {
            dpiObj = { dpi, status: "Marginal", color: "#EAEA48", textColor: "#000000" }
        } else if (dpi < 100) {
            dpiObj = { dpi, status: "Poor", color: "#BF0000", textColor: "#FFF" }
        }

        return dpiObj;
    };

    const getHeightInch = (value, cImage, dpiObj) => {
        const change_percentage = (value / cImage.sizeObjConst.width_inch) * 100;
        cImage.sizeObj.height_inch = parseFloat(parseFloat((cImage.sizeObjConst.height_inch / 100) * change_percentage).toFixed(2));
        cImage.sizeObj.gang_height = getArtGangWidth(cImage.sizeObj.height_inch);
        return cImage;
    }

    const getWidthInch = (value, cImage, dpiObj) => {
        const change_percentage = (value / cImage.sizeObjConst.height_inch) * 100;
        cImage.sizeObj.width_inch = parseFloat(parseFloat((cImage.sizeObjConst.width_inch / 100) * change_percentage).toFixed(2));
        dpiObj = getDPI(cImage.sizeObjConst.width_px, cImage.sizeObj.width_inch);
        cImage.sizeObj.gang_height = getArtGangWidth(cImage.sizeObj.height_inch);
        return cImage;
    }

    const getHeight_Width = (value, cImage, maxHeight) => {

        if (cImage.sizeObj.height_px > cImage.sizeObj.width_px && maxHeight) {
            value = 210;
        }

        let height_px;
        if ((cImage.sizeObj.width_px) < cImage.sizeObj.height_px && maxHeight) {
            height_px = 250;

            const change_percentage = (height_px / cImage.sizeObjConst.height_px) * 100;
            value = parseFloat(parseFloat((cImage.sizeObjConst.width_px / 100) * change_percentage).toFixed(2));

        } else {
            if (cImage.sizeObjConst.height_px > 400 && cImage.sizeObjConst.width_px < cImage.sizeObjConst.height_px) {
                height_px = 400;

                const change_percentage = (height_px / cImage.sizeObjConst.height_px) * 100;
                value = parseFloat(parseFloat((cImage.sizeObjConst.width_px / 100) * change_percentage).toFixed(2));

            } else {
                const change_percentage = (value / cImage.sizeObjConst.width_px) * 100;
                height_px = parseFloat(parseFloat((cImage.sizeObjConst.height_px / 100) * change_percentage).toFixed(2));

                if (maxHeight && height_px > maxHeight) {
                    height_px = maxHeight;
                }
            }

        }

        return {
            width: value,
            height: height_px
        }

    }

    const onChangeConfig = (index, type, value) => {
        let cImages = JSON.parse(JSON.stringify(images));
        let cImage = cImages[index];
        let setted = false;
        if (cImage) {
            let dpiObj = cImage.sizeObj.dpiObj;

            if (value && !isNaN(value)) {
                let nValue = parseFloat(parseFloat(value).toFixed(2));
                if (type === "width_inch") {
                    dpiObj = getDPI(cImage.sizeObjConst.width_px, value);
                    cImage.sizeObj.width_px = value * dpiObj.dpi;
                    cImage = getHeightInch(value, cImage, dpiObj);
                }

                if (type === "height_inch") {
                    if (nValue > sheetWidth) {
                        value = sheetWidth;
                    }
                    cImage.sizeObj.height_px = value * cImage.sizeObj.dpi;
                    cImage = getWidthInch(value, cImage, dpiObj);

                    // if (cImage.sizeObj.width_inch > sheetWidth && cImage.sizeObj.height_inch > sheetWidth) {
                    //     toast.warning("Height should not be greater than sheet height.");
                    //     return;
                    // }
                }

                if (type === "sizeUp") {
                    const sizeUpValue = parseFloat(sheetWidth / value);
                    const sizeUpValue1 = parseFloat((sheetWidth - ((2 * value) - 2) * imageMargin) / value).toFixed(2);
                    // cImage.sizeObj.width_inch = parseFloat((value === 1) ? cImage.sizeObjConst.width_inch : (sizeUpValue - imageMargin).toFixed(2));
                    cImage.sizeObj.width_inch = parseFloat((value === 1) ? cImage.sizeObjConst.width_inch : sizeUpValue1);
                    cImage.sizeObj.copies = value;
                    const change_percentage = (cImage.sizeObj.width_inch / cImage.sizeObjConst.width_inch) * 100;
                    cImage.sizeObj.height_inch = parseFloat(parseFloat((cImage.sizeObjConst.height_inch / 100) * change_percentage).toFixed(2));

                    dpiObj = getDPI(cImage.sizeObjConst.width_px, cImage.sizeObj.width_inch);
                    cImage.sizeObj.gang_height = getArtGangWidth(cImage.sizeObj.height_inch);
                }

                cImage.sizeObj.dpiObj = dpiObj;
            }

            if (!setted) {
                cImage.sizeObj[type] = value;
            }
            cImages[index] = cImage;
            setImages(cImages);
        }
    }

    const removeArt = (index) => {
        if (window.confirm("Are you sure you want to remove this art?")) {
            let cImages = JSON.parse(JSON.stringify(images));
            cImages.splice(index, 1);
            setImages(cImages);
            modifyImages(cImages);

            localStorage.setItem("images", JSON.stringify(cImages));
            if (cImages.length === 0) {
                return navigate("/");
            }
        }
    }

    const modifyImages = (cImages) => {
        const tArtId = artId || artId_Local;
        if (tArtId) {
            // apiService.update("arts", { ...artLoaded, data: cImages }, artLoaded._id);
            apiService.update("arts/set-images", cImages, tArtId);
        }
    }

    const onFilesUpload = async (files) => {
        if (!uploaderBusy) {
            try {

                let invalidFiles = files.filter(f => f.type !== "image/png");
                if (invalidFiles.length > 0) {
                    toast.error("Invalid file extension detected only upload .png image files!")
                }

                debugger;
                let pngFiles = files.filter(f => f.type === "image/png");
                if (pngFiles.length > 0) {
                    let uFiles = [];
                    setUploaderBusy(true);
                    let sizes = [];
                    for (let i = 0; i < pngFiles.length; i++) {
                        let file = pngFiles[i];
                        const size = await fetchImageSize(file);
                        uFiles.push(size.file);
                        sizes.push(size);
                    }


                    let g = await uploaderService.mulipleUpload(uFiles);
                    g = g.file_paths.map((c, i) => {
                        return { url: c, sizeObj: sizes[i], sizeObjConst: sizes[i] }
                    });

                    let cImages = localStorage.getItem("images");
                    cImages = JSON.parse(cImages);
                    cImages = cImages.concat(g);
                    setImages(cImages);

                    localStorage.setItem("images", JSON.stringify(cImages));
                    setUploaderBusy(false);

                    modifyImages(cImages);
                }
            } catch (error) {
                toast.error("Unable to upload images!");
                setUploaderBusy(false)
            }
        }
    }

    const handleCreateGang = async () => {
        setWizardBusy(true);

        let mArtLoaded = null, tArtId = artId || artId_Local;
        if (tArtId) {
            mArtLoaded = await apiService.update("arts/set-images", images, tArtId);
        }

        let webFilesToGang = [];
        for (let i = 0; i < images.length; i++) {
            const image = images[i];
            webFilesToGang.push({
                "imageURL": getImageURL(image.url),
                "requestedImageWidth": parseFloat(image.sizeObj.width_inch),
                "requestedImageHeight": parseFloat(image.sizeObj.height_inch),
                "requestedImageCopies": parseInt(image.sizeObj.copies)
            })
        }

        let data = {
            "token": "TheOneTheOnlyTheTestToken",
            "gangSettings": {
                "maxPrintWidth": sheetWidth,
                "maxPrintHeight": sheetLength,
                "margin": imageMargin,
                "dpi": 300,
                "rotate180": rotateGang
            },
            "webFilesToGang": webFilesToGang
        }

        try {

            data.gangSettings.dpi = 30;
            let gResPreview = await axios.post("https://gsapi.azurewebsites.net/GangSheet", data);
            gResPreview = gResPreview.data;
            loadPreviewImages(gResPreview)

            data.gangSettings.dpi = 300;
            let gRes = await axios.post("https://gsapi.azurewebsites.net/GangSheet", data);
            gRes = gRes.data;

            let mWizards = JSON.parse(JSON.stringify(wizards));
            mWizards[2].active = true;
            setWizards(mWizards);

            setDownloadPaths(gRes);
            setActiveWizard(2);
            setWizardBusy(false);

            if (mArtLoaded) {
                localStorage.setItem(`a-${mArtLoaded._id}`, true);
                setArtLocalId(`a-${mArtLoaded._id}`);
                apiService.update('arts', {
                    ...mArtLoaded, data: images,
                    generatedURLs: gRes,
                    generatedURLsPreview: gResPreview
                }, mArtLoaded._id);
            }

        } catch (error) {
            console.log({ error });
            toast.error("An Internal Error Occurred, Please try again later!");
            setWizardBusy(false);
            return;
        }

    }

    const handleDownload = () => {
        downloadPaths.forEach(path => {
            window.open(path, '_blank');
        });
    }

    const onFilesUploadAttach = async (files) => {
        setattachmentFile(null);
        // let imageFiles = files.filter(f => f.type.includes("image"));
        // if (imageFiles.length === 0) {
        //     toast.error("Error: Invalid Attachment Type. Please select an image file as an attachment!");
        //     return;
        // }
        setattachmentFile(files);
    }

    const onChangeSharingForm = (name, value) => {
        let tsharingData = JSON.parse(JSON.stringify(sharingData));
        tsharingData[name] = value;
        setSharingData(tsharingData);
    }

    const getLink = () => {
        return `${config.shareableURL}/#/art/${artLoaded._id}/${editable ? 'edit' : 'download'}`;
    }

    const onSendEmail = async () => {
        const sharingDataKeys = Object.keys(sharingData);
        for (let i = 0; i < sharingDataKeys.length; i++) {
            const name = sharingDataKeys[i];
            if (sharingData[name] === "") {
                console.log(name, sharingData[name]);
                return toast.error(`Error: Please complete email form to proceed sharing.`);
            }
        }
        try {
            await apiService.addWithFile('arts/share/email', {
                ...sharingData,
                link: getLink(),
            }, attachmentFile);
            toast.success(`Success! The created Gang-Sheet has been successfully delivered to your recipient's email.`);
            setShareModal(false);
        } catch (error) {
            console.log(error);
            toast.error("An Error Occurred While Sending Email: Please check your internet connection and email settings, then try again.")
        }
    }

    const onChangeWizard = (index) => {
        if (index == 1) {
            setActiveWizard(index);
        }
    }

    const onApplyLabel = async (index) => {
        if (quickToolsLabel !== "") {
            try {
                const res = await apiService.add('arts/add/label', { label: quickToolsLabel, path: images[index].url });
                setLabeledImage(res.path);
            } catch (error) {
                console.log({ error });
            }
        }

    }

    const onSaveLabel = async (index) => {
        if (labeledImage) {
            let cImages = JSON.parse(JSON.stringify(images));
            cImages[index].url = labeledImage;

            setImages(cImages);
            setLabeledImage(null);


            let mArtLoaded = null, tArtId = artId || artId_Local;

            if (tArtId) {
                mArtLoaded = await apiService.update("arts/set-images", cImages, tArtId);
            }

            setQuickToolsModal(false);
            toast.success(`Label Added!`);
        }

    }

    const fetchImageSizeURL = (url) => {
        let image = new Image();
        image.src = url;

        return new Promise((resolve, reject) => {
            image.onload = function () {
                resolve({
                    height: this.height,
                    width: this.width
                })
            }
        })
    }

    const loadPreviewImages = async (urls) => {
        let dWidth = document.documentElement.scrollWidth / (isMobile ? 1.65 : 4.6);
        console.log({ dWidth });
        let width_px = dWidth;
        let nURLs = [];
        for (let i = 0; i < urls.length; i++) {
            const url = urls[i];
            const { height, width } = await fetchImageSizeURL(url);

            let height_px = dWidth;
            if (height > width_px && height > width) {
                const change_percentage = (height_px / height) * 100;
                width_px = parseFloat(parseFloat((width / 100) * change_percentage).toFixed(2));
            } else {
                const change_percentage = (width_px / width) * 100;
                height_px = parseFloat(parseFloat((height / 100) * change_percentage).toFixed(2));
            }

            nURLs.push({ url, size: { height_px, width_px } });
        }

        setDownloadPathsPreview(nURLs);
    }


    return (
        <div className='art-container'>

            {shareModal && <ShareModal setShareModal={setShareModal} title="Share" width={40} link={getLink()}>
                <div className='clip-board-container'>
                    {artLocalId && <div className='ced-container' onClick={() => seteditable(!editable)}>
                        <i class={editable ? "fa-solid fa-circle-check" : "fa-regular fa-circle"}></i>
                        <span>Editable?</span>
                    </div>}
                    {/* <p className='art-wizard-heading primary-dark-text'>Link Sharing</p>
                    <span className='clipboard-sub-heading'>Publicly accessible link to view 'Gang-Sheet' online!</span>

                    <div className='row'>
                        <div className='art-clipboard-button upload-submit-btn' onClick={() => { navigator.clipboard.writeText(getLink()); toast.success("Copied") }}>
                            <i class="fa-solid fa-link"></i>
                            <span>Copy Link</span>
                        </div>
                    </div> */}

                    {/* <p className='art-wizard-heading'>- or -</p> */}
                    <p className='art-wizard-heading mt-10 primary-dark-text'>Emailing Gangsheet</p>
                    <span className='clipboard-sub-heading'>
                        Send a copy of your gang sheets via email<br />
                        You can even send an additional attachment <br />
                        (like a shipping label)
                    </span>

                    <TextField name='from' label='From' variant='standard' className='sharing-field clipboard-form'
                        value={sharingData.from} onChange={(e) => onChangeSharingForm('from', e.target.value)} />

                    <TextField name='recipient' label='Recipient Email' variant='standard' className='sharing-field'
                        value={sharingData.to} onChange={(e) => onChangeSharingForm('to', e.target.value)} />

                    <TextField name='subject' label='Subject' variant='standard' className='sharing-field'
                        value={sharingData.subject} onChange={(e) => onChangeSharingForm('subject', e.target.value)} />

                    <div>
                        <p className='sharing-field-label'>Message</p>
                        <TextareaAutosize aria-label="minimum height"
                            minRows={5} placeholder="Message"
                            className='sharing-field stext-area'
                            value={sharingData.text}
                            onChange={(e) => onChangeSharingForm('text', e.target.value)}
                        />
                    </div>
                    {
                        attachmentFile && <div className='art-share-attach-container sharing-field '>
                            <span className='art-share-attach'>
                                {attachmentFile.length === 1 ? attachmentFile[0].name : `${attachmentFile.length} files selected`}
                            </span>
                        </div>
                    }
                    <div className='row mt-5'>
                        <ImageDragDrop onFilesUpload={onFilesUploadAttach}>
                            <div className='art-share-button art-clipboard-button upload-submit-btn mt-5 ml-0'>
                                <i class="fa-solid fa-paperclip"></i>
                                <span>Attachment</span>
                            </div>
                        </ImageDragDrop>

                        <div className='art-clipboard-button upload-submit-btn mt-5' onClick={onSendEmail}>
                            <i class="fa-solid fa-envelope"></i>
                            <span>Send Email</span>
                        </div>
                    </div>
                </div>
            </ShareModal>}

            {quickToolsModal && quickToolImage && <QuickToolsModal sidebarVisible={sidebarVisible} setSidebarVisible={setSidebarVisible} isMobile={isMobile} className="qtm-container" title="Quick Tools" setQuickToolsModal={setQuickToolsModal}>
                {(isMobile && sidebarVisible) || !isMobile ? <div className='qtm-sidebar'>

                    {quickToolsSideMenu.map((q, i) => <div className={`qtm-nav ${i === quickToolsActive ? 'active' : ''}`} onClick={() => setQuickToolsActive(i)}>
                        <i class={q.icon}></i>
                        <span>{q.name}</span>
                    </div>)}


                    <div className='art-sizeup-row-container row center'>
                        {sizeUp.map((s, sIndex) => <div onClick={() => onChangeConfig(quickToolsIndex, "sizeUp", s)}
                            className={`art-sizeup-item ${quickToolImage.sizeObj.sizeUp === s ? 'active' : 'inactive'}`}
                            ey={sIndex}>
                            {s}x
                        </div>)}
                    </div>

                    <div className='qtm-sizeup-container'>
                        {
                            quickSizeData.map((cSize, index) => <div className='qtm-sizeup-item'>
                                <h2 className='qtm-sizeup-heading'>{cSize.category}</h2>
                                <div className='qtm-sizeup-buttons'>
                                    {cSize.data.map((cButton, index2) => <div className='qtm-sizeup-button' onClick={() => onChangeConfig(quickToolsIndex, "width_inch", cButton.value)}>
                                        <i class="fa-solid fa-shirt"></i>
                                        <span>{cButton.name}</span>
                                    </div>)}
                                </div>
                            </div>)
                        }
                    </div>
                </div> : null}
                <div className='qtm-content'>
                    {quickToolsActive === 0 && <Fragment>
                        <div className='qtm-preview-image row center'>
                            <div className='art-preview-transparent' style={{
                                ...getHeight_Width(document.documentElement.scrollWidth / (isMobile ? 1.4 : 4), quickToolImage)
                            }}>
                                <div className='art-preview-image'
                                    style={{ backgroundImage: `url(${getImageURL(quickToolImage.url)})` }}>
                                </div>
                            </div>
                        </div>
                        <div className='qtm-tool-container'>
                            <div className='qtm-fields'>
                                <TextField label="Width"
                                    value={quickToolImage.sizeObj.width_inch}
                                    onChange={(e) => onChangeConfig(quickToolsIndex, "width_inch", e.target.value)}
                                    variant='standard' className='art-bottom-config-field qtm-field' />

                                <TextField label="Height"
                                    value={quickToolImage.sizeObj.height_inch} onChange={(e) => onChangeConfig(quickToolsIndex, "height_inch", e.target.value)}
                                    variant='standard' className='art-bottom-config-field qtm-field' />

                                <TextField label="Copies"
                                    value={quickToolImage.sizeObj.copies} onChange={(e) => onChangeConfig(quickToolsIndex, "copies", e.target.value)}
                                    variant='standard' className='art-bottom-config-field qtm-field' />
                            </div>

                            <div className='qtm-buttons'>
                                <div className='upload-submit-btn qtm-button' onClick={() => { setQuickToolsModal(false) }}>
                                    <i class="fa-solid fa-check"></i>
                                    <span>
                                        Save
                                    </span>
                                </div>
                            </div>
                        </div>
                    </Fragment>}

                    {quickToolsActive == 4 && <Fragment>
                        <div className='qtm-preview-image row center'>
                            <div className='art-preview-transparent' style={{
                                ...getHeight_Width(document.documentElement.scrollWidth / (isMobile ? 1.4 : 4.3), quickToolImage)
                            }}>
                                <div className='art-preview-image'
                                    style={{
                                        backgroundImage: `url(${getImageURL(labeledImage ? labeledImage : quickToolImage.url)})`
                                    }}>
                                </div>
                            </div>

                        </div>

                        <div className='qtm-tool-container qtm-tool-label'>
                            {/* <div className='qtm-fields'> */}
                            <TextField label="Label"
                                value={quickToolsLabel}
                                onChange={(e) => setQuickToolsLabel(e.target.value)}
                                variant='standard' className='art-bottom-config-field qtm-label-field' />
                            {/* </div> */}

                            <div className='upload-submit-btn qtm-button qtm-button-gray ml-5' onClick={() => onApplyLabel(quickToolsIndex)}>
                                <i class="fa-solid fa-tag"></i>
                                <span>
                                    Apply
                                </span>
                            </div>
                            <div className='upload-submit-btn qtm-button ml-5' onClick={() => onSaveLabel(quickToolsIndex)}>
                                <i class="fa-solid fa-check"></i>
                                <span>
                                    Save & Close
                                </span>
                            </div>
                        </div>
                    </Fragment>}

                    {quickToolsSideMenu[quickToolsActive] && quickToolsSideMenu[quickToolsActive].development && <div className='qtm-ud'>
                        <i class="fa-solid fa-circle-exclamation"></i>
                        <span>Under Development</span>
                    </div>}
                </div>
            </QuickToolsModal>}
            <div className='art-wizard-line-container row center'>
                <div className='art-wizard-line'>
                </div>
            </div>

            <div className='art-wizard-container row'>
                {wizards.map((wizard, index) => <div className={`art-wizard-item column center`} onClick={() => onChangeWizard(index)}>
                    <div className='art-wizard-item-sub column center'>
                        <div className={`art-wizad-icon-container ${wizard.active ? 'active' : 'inactive'}`}
                            style={company ? { backgroundColor: wizard.active ? company.primaryColorCode : company.secondaryColorCode } : null}
                        >
                            <img src={wizard.icon} className='art-wizard-icon' />
                        </div>
                        <span className='art-wizard-title'>{wizard.name}</span>
                    </div>
                </div>)}


            </div>
            {
                activeWizard == 1 &&
                (wizardBusy ?
                    <div className='art-wizard-content'>
                        <div className='art-wizard-busy row center'>
                            <APILoader height={100} />
                        </div>

                    </div>
                    :
                    <div className='art-wizard-content'>
                        <p className='art-wizard-heading'>Configuration</p>
                        <div className='art-config-container row'>
                            <div className='art-config-field-wrapper'>
                                <TextField label="Sheet Width (in)"
                                    value={sheetWidth} onChange={(e) => setSheetWidth(e.target.value)}
                                    variant='standard' className='art-config-field' />
                            </div>

                            <div className='art-config-field-wrapper'>
                                <TextField label="Sheet Length Max (in)"
                                    value={sheetLength} onChange={(e) => setsheetLength(e.target.value)}
                                    variant='standard' className='art-config-field' />
                            </div>

                            <div className='art-config-field-wrapper'>
                                <TextField label="Image Margin"
                                    value={imageMargin} onChange={(e) => setimageMargin(e.target.value)}
                                    variant='standard' className='art-config-field' />
                            </div>

                            <div className='art-config-field-wrapper rotate row' onClick={() => setRotateGang(!rotateGang)}>
                                <span className='art-rotate-gang-label'>Rotate Gang 180°</span>
                                <div className='art-rotate-icon-container'>
                                    <i class={`${rotateGang ? 'fa-solid fa-circle-check check' : 'fa-regular fa-circle'} art-rotate-icon`}
                                        style={company ? { color: company.primaryColorCode } : null}
                                    ></i>
                                </div>
                            </div>
                        </div>
                        <p className='art-wizard-heading'>Select Size and Quantities</p>
                        <div className='art-preview-container row'>
                            {images.map((art, index) => <div className='art-preview-item' key={index}>
                                <div className='art-preview-dpi row center' style={{ backgroundColor: art.sizeObj.dpiObj.color, color: art.sizeObj.dpiObj.textColor }}>
                                    {art.sizeObj.dpiObj.dpi} DPI ({art.sizeObj.dpiObj.status})
                                </div>


                                <div className='art-preview-item-overlay'>
                                    <div className='art-remove-icon' onClick={() => removeArt(index)}>
                                        <i class="fa-solid fa-xmark"></i>
                                    </div>
                                    {/* <div className='art-size-container'
                                        style={company ? { color: company.primaryColorCode } : null}
                                        onClick={() => { setQuickToolsIndex(index); setQuickToolsModal(true) }}>
                                        <i class="fa-solid fa-screwdriver-wrench"></i>
                                    </div> */}

                                    <div className='art-preview-transparent'
                                        style={{
                                            ...getHeight_Width(312, art, 250)
                                        }}
                                    // style={{ backgroundImage: 'url(assets/images/transparent.jpeg)' }}
                                    >
                                        <div className='art-preview-image' style={{ backgroundImage: `url(${getImageURL(art.url)})` }}>

                                        </div>
                                    </div>
                                </div>

                                <div className='art-bottom-content'>
                                    <div className='row art-bottom-config'>
                                        <TextField label="Width"
                                            value={art.sizeObj.width_inch}
                                            onChange={(e) => onChangeConfig(index, "width_inch", e.target.value)}
                                            variant='standard' className='art-bottom-config-field' />

                                        <TextField label="Height"
                                            value={art.sizeObj.height_inch} onChange={(e) => onChangeConfig(index, "height_inch", e.target.value)}
                                            variant='standard' className='art-bottom-config-field' />

                                        <TextField label="Copies"
                                            value={art.sizeObj.copies} onChange={(e) => onChangeConfig(index, "copies", e.target.value)}
                                            variant='standard' className='art-bottom-config-field' />
                                    </div>
                                    {/* <div className='art-sizeup-container column center'>
                                        <p className='size-up-heading'>
                                            Quick Size Up
                                        </p>
                                        <div className='art-sizeup-row-container row center'>
                                            {sizeUp.map((s, sIndex) => <div onClick={() => onChangeConfig(index, "sizeUp", s)} className={`art-sizeup-item ${art.sizeObj.sizeUp === s ? 'active' : 'inactive'}`} key={sIndex}>
                                                {s}x
                                            </div>)}
                                        </div>
                                    </div> */}
                                </div>
                            </div>)}

                            <ImageDragDrop className='art-preview-item upload column center' onFilesUpload={onFilesUpload}>
                                {uploaderBusy ?
                                    <APILoader />
                                    : <Fragment>
                                        <PlusIcon className="upload-plus-icon" color={company ? company.primaryColorCode : null} />
                                        <p className='upload-title'>Add images to get started</p>
                                        <span className='uplaod-caption'>
                                            Simply drag and drop
                                        </span>
                                        <span className='uplaod-caption'>
                                            - or click to select files!</span>
                                    </Fragment>}
                            </ImageDragDrop>
                        </div>

                        <div className='row center'>
                            <div className='upload-submit-btn art-submit-button'
                                style={company ? { backgroundColor: company.secondaryColorCode } : null}
                                onClick={handleCreateGang}>
                                Next
                            </div>
                        </div>
                    </div>)
            }

            {
                activeWizard === 2 && <div className='art-wizard-content column center'>
                    <span className='art-download-title'>Your Gang Sheet is Ready!</span>
                    {/* <span className='art-download-caption'>Download Available Now</span> */}
                    <div className='art-download-image-main-container'>
                        {
                            downloadPathsPreview.map((image, index) => <div
                                className='art-download-image-container column center' key={index}

                            // style={{ backgroundImage: 'url(assets/images/transparent.jpeg)' }}
                            >
                                <div className='art-preview-transparent' style={{ height: image.size.height_px, width: image.size.width_px }}>
                                    <div className='art-download-image' style={{ backgroundImage: `url(${image.url})` }} />
                                </div>


                            </div>)
                        }

                        {/* {
                            downloadPathsPreview.map((image, index) => <div className='art-download-image-container column center'>
                                <img src={image} width={isMobile ? 280 : 300} className='art-download-img' />
                            </div>)
                        } */}
                    </div>
                    <div className='art-download-button-container row center'>
                        <div className='upload-submit-btn art-share-button row center'
                            style={company ? { backgroundColor: company.primaryColorCode } : null}
                            onClick={handleDownload} target='__blank'>
                            <img src='assets/wizard-icons/cloud.png' />
                            <span>
                                Download
                            </span>
                        </div>
                        <div className='upload-submit-btn art-download-button row center'
                            style={company ? { backgroundColor: company.secondaryColorCode } : null}
                            onClick={() => setShareModal(true)}>
                            <img src='assets/icons/share.png' />
                            <span>
                                Share
                            </span>
                        </div>
                    </div>

                    <div className='art-link' onClick={() => {
                        // if (artLocalId || type === "edit") {
                        //     setActiveWizard(1); setDownloadPaths([])
                        // } else {
                        navigate(company ? `/c/${company._id}` : '/')
                        // }
                    }}>Create another Gang!</div>

                </div>
            }


        </div >
    );
};

export default Art;