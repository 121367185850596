import React, { useEffect, useState } from 'react';
import './style.css';
import { Link, useLocation } from 'react-router-dom';
import useOuterClick from '../useOuterClick';
import { apiService } from '../../_services/api.service';
import getImageURL from '../../_helpers/imageHelper';

const Header = ({ companyCallBack, company }) => {
    const isMobile = window.matchMedia('screen and (max-width: 768px)').matches ? true : false;
    const [headerVisible, setheaderVisible] = useState(!isMobile);

    const { pathname } = useLocation();

    useEffect(() => {
        const companyPrefix = '/c/'
        if (pathname.includes(companyPrefix)) {
            let companyId = pathname.split(companyPrefix)[1];
            companyId = companyId.split('/')[0];

            apiService.getSingle('companies', companyId)
                .then((c) => {
                    window.document.title = c.name;
                    companyCallBack(c)
                })
                .catch(e => console.log({ e }))
        }
    }, []);

    const closeMenu = () => {
        if (isMobile && headerVisible) {
            setheaderVisible(false);
        }
    }

    const headerBarRef = useOuterClick(e => {
        closeMenu();
    });

    return (
        <div className='header-container' ref={headerBarRef}>
            <Link to={company ? `/c/${company._id}` : "/"} onClick={closeMenu}>
                <img src={company ? getImageURL(company.logo) : "assets/images/logo.png"} className='logo-img' />
            </Link>

            {/* {isMobile && <i className="fa-solid fa-bars header-mobile-icon"
                onClick={() => setheaderVisible(!headerVisible)}></i>}

            {headerVisible && <div className='header-bar' onClick={closeMenu}>
                <Link to="/pricing" className='header-link'>
                    Pricing
                </Link>
                <Link to="/features" className='header-link'>
                    Features
                </Link>
                <Link to="/company" className='header-link'>
                    Company
                </Link>
                <Link to="/help" className='header-link'>
                    Help
                </Link>

            </div>} */}



        </div>
    );
};

export default Header;