import './App.css';
import { HashRouter } from "react-router-dom";
import Router from './Router';
import Header from './components/Header';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from './components/Footer';
import { useState } from 'react';
import AppProvider from './components/AppProvider';

function App() {
  const [company, setCompany] = useState(null);

  return (
    <HashRouter>
      <AppProvider company={company} >
        <ToastContainer />
        <Header companyCallBack={setCompany} company={company} />
        <Router company={company} />
        <Footer />
      </AppProvider>
    </HashRouter>


  );
}

export default App;
