import React from 'react';
import { Route, Routes } from "react-router-dom";
import Home from './pages/Home';
import Art from './pages/Art';
import Page from './pages/Page';

const Router = ({ company }) => {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/art" element={<Art />} />
            <Route path="/art/:artId/:type" element={<Art />} />
            <Route path='/p/:slug' element={<Page />} />
            <Route path="/c/:companyId" element={<Home company={company} />} />
            <Route path="/c/:companyId/art" element={<Art company={company} />} />
        </Routes>
    );
};

export default Router;