import React from 'react';
import { toast } from 'react-toastify';

const ShareModal = ({ title, setShareModal, children, width, className, link }) => {
    const handleClose = () => {
        setShareModal(false);
    }
    return (
        <div className='modal share-modal'>
            <div className="modal-content share-modal-content" style={{ width: `${width ? width : 60}%` }}>
                <div className='modal-header'>
                    <span>{title}</span>
                    <div className='row center-vertical'>
                        <div className='copy-link-modal' onClick={() => { navigator.clipboard.writeText(link); toast.success("Copied") }}>
                            <i class="fa-solid fa-link"></i>
                            <span>Copy Link</span>
                        </div>
                        <span className="modal-close" onClick={handleClose}>&times;</span>
                    </div>

                </div>
                <div className={`modal-body ${className ? className : ''}`}>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default ShareModal;