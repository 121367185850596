import React, { useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';

const ImageDragDrop = ({ className, children, onFilesUpload }) => {

    const onDrop = useCallback(acceptedFiles => {
        onFilesUpload && onFilesUpload(acceptedFiles);
        // Do something with the files
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    return (
        <div {...getRootProps()} className={className}>
            <input {...getInputProps()} className="hidden" />
            {children}
        </div>
    )
};

export default ImageDragDrop;