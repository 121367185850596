
import { Buffer } from 'buffer';
const _URL = window.URL || window.webkitURL;


const fetchImageSize = (file) => {
    return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function (e) {
            //Initiate the JavaScript Image object.
            let image = new Image();
            //Set the Base64 string return from FileReader as source.
            image.src = e.target.result;


            //Validate the File Height and Width.
            image.onload = function () {
                try {
                    console.log({ before: { height: this.height, width: this.width } });
                    let image2 = new Image();
                    image2.src = trimCanvasFromImage(this);
                    if (image2) {
                        console.log({ image2 });
                        image2.onload = function () {

                            console.log({ after: { height: this.height, width: this.width } });
                            let height_px = this.height;
                            let width_px = this.width;

                            let dpi = 300;
                            let height_inch = parseFloat((height_px / dpi).toFixed(2));
                            let width_inch = parseFloat((width_px / dpi).toFixed(2));

                            console.log({ height_px, width_px, height_inch, width_inch });

                            let newFile = dataUrlToFile(image2.src, file.name)
                            resolve({
                                height_px, width_px,
                                height_inch, width_inch,
                                dpiObj: { dpi: 300, status: "Excellent", color: "#10AACC", textColor: "#FFF" },
                                gang_height: 0, sizeUp: 1, copies: 1,
                                // local_url: _URL.createObjectURL(file)
                                local_url: _URL.createObjectURL(newFile),
                                file: newFile
                            });
                        }
                    }


                } catch (error) {
                    console.log({ error });
                    reject(error);
                }

            };

        };
    })
}
const dataUrlToFile = (dataUrl, filename) => {
    const arr = dataUrl.split(',');
    if (arr.length < 2) { return undefined; }
    const mimeArr = arr[0].match(/:(.*?);/);
    if (!mimeArr || mimeArr.length < 2) { return undefined; }
    const mime = mimeArr[1];
    const buff = Buffer.from(arr[1], 'base64');
    return new File([buff], filename, { type: mime });
}

function trimCanvasFromImage(img) {
    var c = document.createElement('canvas');
    if (img.width > 0) {
        var w = img.width, h = img.height;

        c.width = w;
        c.height = h;

        c.getContext('2d')
            .drawImage(img, 0, 0, w, h);

        // ctx.drawImage(img, 0, 0, w, h);
        // var imageData = ctx.getImageData(0, 0, w, h);
        // var pixel = imageData.data;

        // var r = 0, g = 1, b = 2, a = 3;
        // for (var p = 0; p < pixel.length; p += 4) {
        //     if (
        //         pixel[p + r] == 255 &&
        //         pixel[p + g] == 255 &&
        //         pixel[p + b] == 255) // if white then change alpha to 0
        //     { pixel[p + a] = 0; }
        // }

        // ctx.putImageData(imageData, 0, 0);

        c = trimCanvas(c);



        return c.toDataURL('image/png');
    }
    return null;
}

function trimCanvas(c) {
    var ctx = c.getContext('2d'),
        copy = document.createElement('canvas').getContext('2d'),
        pixels = ctx.getImageData(0, 0, c.width, c.height),
        l = pixels.data.length,
        i,
        bound = {
            top: null,
            left: null,
            right: null,
            bottom: null
        },
        x, y;

    for (i = 0; i < l; i += 4) {
        if (pixels.data[i + 3] !== 0) {
            x = (i / 4) % c.width;
            y = ~~((i / 4) / c.width);

            if (bound.top === null) {
                bound.top = y;
            }

            if (bound.left === null) {
                bound.left = x;
            } else if (x < bound.left) {
                bound.left = x;
            }

            if (bound.right === null) {
                bound.right = x;
            } else if (bound.right < x) {
                bound.right = x;
            }

            if (bound.bottom === null) {
                bound.bottom = y;
            } else if (bound.bottom < y) {
                bound.bottom = y;
            }
        }
    }

    var trimHeight = bound.bottom - bound.top,
        trimWidth = bound.right - bound.left,
        trimmed = ctx.getImageData(bound.left, bound.top, trimWidth, trimHeight);

    copy.canvas.width = trimWidth;
    copy.canvas.height = trimHeight;
    copy.putImageData(trimmed, 0, 0);

    // Return trimmed canvas
    return copy.canvas;
}

export default fetchImageSize;