import React from 'react';

const PlusIcon = ({ className, color }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 127 127" fill="none">
            <g filter="url(#filter0_d_1_18)">
                <circle cx="63.5" cy="59.5" r="59.5" fill={color ? color : "#6C35DE"} />
            </g>
            <rect x="59.8802" y="32.9233" width="8.63947" height="52.4539" fill="white" />
            <rect x="37.6645" y="63.1615" width="8.63947" height="52.4539" transform="rotate(-90 37.6645 63.1615)" fill="white" />
            <defs>
                <filter id="filter0_d_1_18" x="0" y="0" width="127" height="127" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1_18" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1_18" result="shape" />
                </filter>
            </defs>
        </svg>
    );
};

export default PlusIcon;