import React from 'react';
import './style.css';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <div className='f-container'>
            <Link to="/p/advertise">
                Advertise with us
            </Link>
            <span>/</span>
            <Link to="/p/privacy-policy">
                Privacy Policy
            </Link>
            <span>/</span>
            <Link to="/p/terms-of-use">
                Terms of Use
            </Link>

        </div>
    );
};

export default Footer;