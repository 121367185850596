import config from "../_config";

export const uploaderService = {
    mulipleUpload: (files) => {
        let formdata = new FormData();
        files.forEach(file => {
            formdata.append('images', file);
        });
        var requestOptions = { method: 'POST', body: formdata, redirect: 'follow' };
        return fetch(`${config.baseUrl}/api/upload/images`, requestOptions)
            .then(response => response.json());
    }

}