import React from 'react';
import { toast } from 'react-toastify';

const QuickToolsModal = ({ title, sidebarVisible, setSidebarVisible, setQuickToolsModal, isMobile, children, width, className }) => {
    const handleClose = () => {
        setQuickToolsModal(false);
    }
    return (
        <div className='modal tools-modal'>
            <div className="modal-content tools-modal-content" style={{ width: `${width ? width : 80}%` }}>
                <div className='modal-header'>
                    {isMobile && <i class="fa-solid fa-bars" onClick={()=> setSidebarVisible(!sidebarVisible)}></i>}
                    <span>{title}</span>
                    <span className="modal-close" onClick={handleClose}>&times;</span>
                </div>
                <div className={`modal-body ${className ? className : ''}`}>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default QuickToolsModal;