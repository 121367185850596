import React, { useEffect, useState } from 'react';
import { apiService } from '../_services/api.service';
import getImageURL from '../_helpers/imageHelper';
import { APILoader } from './APILoader';
import { useLocation } from 'react-router-dom';

const AppProvider = ({ children, company }) => {
    const [advertiserSliders, setAdvertiserSliders] = useState([]);
    const [loading, setLoading] = useState(false);
    const isMobile = window.matchMedia('screen and (max-width: 768px)').matches ? true : false;
    const [backgroundImage, setBackgroundImage] = useState(null);
    const [sliderEnabled, setSliderEnabled] = useState(false);
    const { pathname } = useLocation();

    useEffect(() => {
        setSliderEnabled(pathname === "/" ? true : false);
    }, [pathname]);

    useEffect(() => {
        let interval = null;
        let advertisersActiveIndex = 0;
        if (advertiserSliders.length > 1) {
            interval = setInterval(() => {
                if (advertisersActiveIndex < advertiserSliders.length - 1) {
                    setBackgroundImage(`url(${getImageURL(advertiserSliders[advertisersActiveIndex + 1].image)})`);
                    advertisersActiveIndex += 1;
                } else {
                    setBackgroundImage(`url(${getImageURL(advertiserSliders[0].image)})`);
                    advertisersActiveIndex = 0
                }
            }, 120 * 1000);
        }

        if (!sliderEnabled && interval) {
            clearInterval(interval);
        }

        return () => {
            clearInterval(interval);
        }
    }, [advertiserSliders, sliderEnabled]);


    useEffect(() => {
        if (company) {
            setBackgroundImage(`linear-gradient(100deg, ${company.bgColorCode},  #211A30)`);
        } else if (!isMobile && sliderEnabled && !pathname.includes('/c/')) {
            setLoading(true);
            apiService.getAll('advertisers').then((a => {
                if (a.length > 0) {
                    setBackgroundImage(`url(${getImageURL(a[0].image)})`);
                    setAdvertiserSliders(a);
                } else {
                    setBackgroundImage('linear-gradient(50deg, #4F23AC,  #211A30)');
                }
                setLoading(false);
            })).catch(e => {
                setLoading(false);
                setBackgroundImage('linear-gradient(50deg, #4F23AC,  #211A30)');
            })
        } else {
            setBackgroundImage('linear-gradient(50deg, #4F23AC,  #211A30)');
        }
    }, [company, sliderEnabled]);

    return loading ?
        <div className='row center height100'>
            <APILoader color="#FFF" />
        </div>
        : (
            <div className='main-containter'
                style={{ backgroundImage }}
            >
                {children}
            </div>
        );
};

export default AppProvider;