import React, { Fragment, useEffect, useState } from 'react';
import './style.css';
import PlusIcon from '../../components/PlusIcon';
import { TextField } from '@mui/material';
import ImageDragDrop from '../../components/ImageDragDrop';
import { toast } from 'react-toastify';
import { uploaderService } from '../../_services/uploader.service';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { apiService } from '../../_services/api.service';
import fetchImageSize from '../../_helpers/fetchImageSize';


const Home = ({ company }) => {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [slectedFilesSizes, setSlectedFilesSizes] = useState([]);
    const [submitBusy, setSubmitBusy] = useState(false);

    const [email, setEmail] = useState("");
    const _URL = window.URL || window.webkitURL;

    const navigate = useNavigate();

    useEffect(() => {
        let tEmail = localStorage.getItem("email");
        if (tEmail && tEmail !== "") {
            setEmail(tEmail);
        }
    }, [company]);


    const onFilesUpload = async (files) => {
        setSelectedFiles([]);
        setSlectedFilesSizes([]);
        let invalidFiles = files.filter(f => f.type !== "image/png");
        if (invalidFiles.length > 0) {
            toast.error("Invalid file extension detected only upload .png image files!")
        }

        let pngFiles = files.filter(f => f.type === "image/png");
        if (pngFiles.length > 0) {
            let sizes = [];
            // setSelectedFiles(pngFiles);
            let sFiles = [];

            for (let i = 0; i < pngFiles.length; i++) {
                let file = pngFiles[i];
                const size = await fetchImageSize(file);
                sFiles.push(size.file);
                sizes.push(size);
            }
            setSelectedFiles(sFiles);
            setSlectedFilesSizes(sizes);
        }

    }

    const onSubmit = async () => {
        if (!submitBusy) {
            if (selectedFiles.length === 0) {
                return toast.error("Please upload image files to continue!");
            }

            if (email === "" || !email) {
                return toast.error("Email is required!");
            }

            var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

            if (!email.match(validRegex)) {
                return toast.error("Invalid Email!");
            }

            setSubmitBusy(true);

            try {
                const subscriber = await apiService.add("subscribers/new", { email });
                localStorage.setItem("email", email);
                let g = await uploaderService.mulipleUpload(selectedFiles);


                g = g.file_paths.map((c, i) => {
                    return { url: c, sizeObj: slectedFilesSizes[i], sizeObjConst: slectedFilesSizes[i] }
                });

                const art = await apiService.add('arts', { subscriber: subscriber._id, data: g });
                // localStorage.setItem("images", JSON.stringify(g));
                localStorage.setItem("art", art._id);


                navigate(company ? `/c/${company._id}/art` : `/art`);
                // navigate(`/art/${art._id}/edit`);
            } catch (e) {
                toast.error("Unable to upload images!");
                setSubmitBusy(false);
            }
        }
    }

    const handleReUpload = () => {
        setSelectedFiles([]);
        setSlectedFilesSizes([]);
    }

    return (
        <div className='home-container'>
            <div className='upload-main-container row center'>
                <div className='upload-container'>
                    <div className='upload-inner-container column center' >
                        {slectedFilesSizes.length === 0 ? <ImageDragDrop className="h-drop-zone column center" onFilesUpload={onFilesUpload}>
                            <PlusIcon className="upload-plus-icon" color={company ? company.primaryColorCode : null} />
                            <p className='upload-title'>Add images to get started</p>
                            <span className='uplaod-caption'>Simply Drag and Drop
                                <br />
                                - or -
                                <br />
                                Click to select files
                            </span>
                        </ImageDragDrop> :
                            <div className='column center'>
                                <div className='u-image-main-container'>
                                    {slectedFilesSizes.length === 1 ?
                                        <div className='u-image-container row center'>
                                            <div style={{ backgroundImage: `url(${slectedFilesSizes[0].local_url}` }} className="u-image" />
                                        </div> :
                                        <div className='u-images-container row center'>
                                            <div className='u-image-container row center u-image-rotate1'>
                                                <div style={{ backgroundImage: `url(${slectedFilesSizes[1].local_url}` }} className="u-image" />
                                            </div>
                                            <div className='u-image-container row center u-image-rotate2'>
                                                <div style={{ backgroundImage: `url(${slectedFilesSizes[0].local_url}` }} className="u-image" />
                                            </div>
                                            {selectedFiles.length > 2 && <span className='image-counter row center'>
                                                {slectedFilesSizes.length}
                                            </span>}
                                        </div>
                                    }
                                </div>
                                <p className='upload-title'>{selectedFiles.length > 1 ? 'Images' : 'Image'} Selected</p>
                                <span className='uplaod-caption'>Please type in your email and click Continue! </span>
                            </div>
                        }
                        <TextField label="Email *" value={email} variant="standard" className='upload-email' onChange={(e) => setEmail(e.target.value)} />

                        <div className='upload-submit-btn' onClick={onSubmit} style={company ? { backgroundColor: company.secondaryColorCode } : null}>
                            {submitBusy ? 'Please Wait...' : 'Continue'}
                        </div>

                        {/* {slectedFilesSizes.length > 0 && <div className='re-upload-button' onClick={handleReUpload}>
                            Re-upload
                        </div>} */}
                    </div>
                </div>
            </div>

            <div className='slide-content column center-horizontal'>
                {/* <h4 className='slide-heading'>[Advertisement]</h4>
                <span className='slide-caption'>
                    Land Your Ad on Our Platform <br />and Reach a Wider Audience. <br />Advertise with Us Today!
                </span>
                <Link to="/advertise" className='silde-button'>
                    Learn More
                </Link> */}
            </div>
            {/* <div className='slide-image-container'>
                <img src='assets/images/slide-image.png' className='slide-image' />
            </div> */}
        </div >
    );
};

export default Home;