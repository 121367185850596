import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { APILoader } from '../../components/APILoader';
import { apiService } from '../../_services/api.service';

const Page = () => {
    const [page, setPage] = useState(null);
    const { slug } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        setPage(null);
        apiService.filter("pages", { "query": { "slug": slug } }).then(pages => {
            if (pages[0]) setPage(pages[0]);
            else {
                navigate("/")
                toast.error("Page not found")
            }
        })
    }, [slug]);
    return (
        <div className='section text-white'>
            {page ? <Fragment>
                <h2>{page.title}</h2>
                <p dangerouslySetInnerHTML={{ __html: page.content }}></p>
            </Fragment>
                :
                <div className='row center'>
                    <APILoader color="#FFF" />
                </div>

            }


        </div>
    );
};

export default Page;